/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.directives').directive('numericFilter', function () {
    return {
      require: 'ngModel',
      link: function link(scope, element, attr, ngModelCtrl) {
        function fromUser(text) {
          var transformedInput = (text + '').replace(/[^0-9]/g, '');

          if (transformedInput !== text) {
            ngModelCtrl.$setViewValue(transformedInput);
            ngModelCtrl.$render();
          }

          return transformedInput; // or return Number(transformedInput)
        }

        ngModelCtrl.$parsers.push(fromUser);
      }
    };
  }).directive('specialCharExceptSpaceFilter', function () {
    return {
      require: 'ngModel',
      link: function link(scope, element, attr, ngModelCtrl) {
        function fromUser(text) {
          var transformedInput = text.replace(/[^a-zA-Z0-9 ]/gi, '');

          if (transformedInput !== text) {
            ngModelCtrl.$setViewValue(transformedInput);
            ngModelCtrl.$render();
          }

          return transformedInput; // or return Number(transformedInput)
        }

        ngModelCtrl.$parsers.push(fromUser);
      }
    };
  });
})();