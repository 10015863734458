/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.directives').filter('timeDuration', function () {
    // Create the return function
    // set the required parameter name to **number**
    return function (timeInSeconds) {
      // Ensure that the passed in data is a number
      if (isNaN(timeInSeconds) || timeInSeconds < 1) {
        // If the data is not a number or is less than one (thus being meaningless) return it unmodified.
        return timeInSeconds;
      } else {
        var moment = require('moment-timezone');

        var momentDurationFormatSetup = require('moment-duration-format');

        momentDurationFormatSetup(moment);
        return timeInSeconds < 60 ? '0:' + moment.duration(parseInt(timeInSeconds, 10), 'seconds').format('ss') : moment.duration(parseInt(timeInSeconds, 10), 'seconds').format('HH:mm:ss');
      }
    };
  });
  angular.module('comcast.directives').filter('smallTimeDuration', function () {
    // Create the return function
    // set the required parameter name to **number**
    return function (timeInMilliseconds) {
      // Ensure that the passed in data is a number
      if (isNaN(timeInMilliseconds) || timeInMilliseconds < 0) {
        // If the data is not a number or is less than one (thus being meaningless) return it unmodified.
        return timeInMilliseconds;
      } else {
        var moment = require('moment-timezone');

        var momentDurationFormatSetup = require('moment-duration-format');

        momentDurationFormatSetup(moment);
        return moment.duration(timeInMilliseconds, 'milliseconds').format('HH:mm:ss.SSS', {
          trim: false
        });
      }
    };
  });
})();