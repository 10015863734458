angular.module('comcast.directives').directive('sizeToHeader', ['$window', function ($window) {
  return {
    restrict: 'A',
    scope: {
      headerId: '@sizeToHeader'
    },
    link: function link(scope, element) {
      angular.element(element[0]).css('width', angular.element(document.getElementById(scope.headerId))[0].clientWidth + 'px');

      $window.onresize = function () {
        scope.$apply();
      };

      scope.$on('$destroy', function () {
        if ($window.off) {
          $window.off('resize');
        }
      });
      scope.$watch(function () {
        return angular.element(document.getElementById(scope.headerId))[0].clientWidth;
      }, function (oldValue, newValue) {
        if (oldValue !== newValue) {
          angular.element(element[0]).css('width', angular.element(document.getElementById(scope.headerId))[0].clientWidth + 'px');
        }
      }, true);
    }
  };
}]);