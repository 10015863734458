angular.module('comcast.directives').directive('updateRepeaterSize', ['$window', function ($window) {
  return {
    restrict: 'A',
    require: '^mdVirtualRepeatContainer',
    link: function link(scope, element, attributes, mdVirtualRepeatContainer) {
      var footer = 120;

      function getHeight() {
        var height = $window.innerHeight - (element[0].getBoundingClientRect().top + footer);
        angular.element(element).css('height', height + 'px');
        return height;
      }

      function onResize() {
        mdVirtualRepeatContainer.setSize_(getHeight());
      }

      getHeight();
      angular.element($window).on('resize', onResize);
    }
  };
}]);