/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.directives').directive('tglSearch', function () {
    function detectmob() {
      if (window.innerWidth > 1024) {
        return true;
      } else {
        return false;
      }
    }

    return {
      restrict: 'E',
      template: '<div id="tsDiv" ng-class="{\'search-closed\':!home.viewSearch, \'search-open\':home.viewSearch}" layout="row" layout-align="end end" ng-show="home.showTgl">\n' + "   <div ng-class=\"{'viewSearch':!home.viewSearch, 'closeSearch':home.viewSearch}\">\n" + '           <md-button class="md-icon-button search-close-button" aria-label="Close Search Button">\n' + '                <i class="fas fa-chevron-left fa-2x" aria-hidden="true"></i>\n' + '            </md-button>\n' + '            <md-button class="md-icon-button search-open-button" aria-label="View Search Button">\n' + '                <i class="fas fa-search fa-2x" aria-hidden="true"></i>\n' + '            </md-button>' + '   </div>\n' + '</div>',
      link: function link(scope, element) {
        scope.home.viewSearch = detectmob();
        element.on('click', '.md-icon-button', function (e) {
          scope.$apply(function () {
            scope.home.viewSearch = !scope.home.viewSearch;
          });
        });
      }
    };
  });
})();