/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.directives').directive('mblDblclick', function () {
    var DblClickInterval = 300; //milliseconds

    var firstClickTime;
    var waitingSecondClick = false;
    return {
      restrict: 'A',
      link: function link(scope, element, attrs) {
        element.bind('click', function (e) {
          if (!waitingSecondClick) {
            firstClickTime = new Date().getTime();
            waitingSecondClick = true;
            setTimeout(function () {
              waitingSecondClick = false;
            }, DblClickInterval);
          } else {
            waitingSecondClick = false;
            var time = new Date().getTime();

            if (time - firstClickTime < DblClickInterval) {
              scope.$apply(attrs.mblDblclick);
            }
          }
        });
      }
    };
  });
})();