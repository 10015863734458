'use strict';
/*
 * This filter was designed to be used by ngRepeat.
 * It takes an input array and a property on each array object and a testing value
 * If the testing value is NULL or false, it will test the property for EITHER (NULL or false)
 * If the testing value is non-NULL and non-false, it will test by ONLY that value
 *
 * This filter is mainly in response to the feature to remove NULLs from booleans in the DB.
 * */

angular.module('comcast.directives').filter('nullOrFalse', function () {
  return function (items, column, testValue) {
    // If no items are passed in, continue passing nothing
    if (!items) {
      return items;
    } // If the test value is EITHER false or NULL, test for BOTH


    if (testValue === null || testValue === false) {
      return items.filter(function (item, index, arr) {
        return item[column] === false || item[column] === null;
      }); // If the test value is something purposeful, test for that
    } else {
      return items.filter(function (item, index, arr) {
        return item[column] === testValue;
      });
    }
  };
});