angular.module('comcast.directives').directive('childPruner', ['$window', '$timeout', function ($window, $timeout) {
  return {
    restrict: 'A',
    scope: {},
    link: function link(scope, element, attrs) {
      // Set the base width to zero, since we can only go up
      scope.maxButtonWidth = 0;
      $timeout(function () {
        // Calculate the starting child widths right away
        var startingChildren = element[0].querySelectorAll('.child');
        var startingWidth = 0;

        for (var i = 0; i < startingChildren.length; i++) {
          var startingComputedStyle = $window.getComputedStyle(startingChildren[i]);
          startingWidth = startingWidth + parseInt(startingChildren[i].offsetWidth, 10) + parseInt(startingComputedStyle.marginLeft, 10) + parseInt(startingComputedStyle.marginRight, 10);
        } // Keep the largest total button width in-memory, so we don't try to toggle back too early


        scope.maxButtonWidth = scope.maxButtonWidth > startingWidth ? scope.maxButtonWidth : startingWidth;
      });

      $window.onresize = function () {
        scope.$apply();
      };

      scope.$on('$destroy', function () {
        if ($window.off) {
          $window.off('resize');
        }
      });

      function reconfigurator() {
        // First, get the width of the childPruner itself
        var parentWidth = element[0].clientWidth; // Next, loop through all children and calculate their total width

        var children = element[0].querySelectorAll('.child');
        var totalWidth = 0;

        for (var i = 0; i < children.length; i++) {
          var computedStyle = $window.getComputedStyle(children[i]);
          totalWidth = totalWidth + parseInt(children[i].offsetWidth, 10) + parseInt(computedStyle.marginLeft, 10) + parseInt(computedStyle.marginRight, 10);
        } // Keep the largest button width in-memory, so we don't try to toggle back too early


        scope.maxButtonWidth = scope.maxButtonWidth > totalWidth ? scope.maxButtonWidth : totalWidth; // GET THE PRUNES

        var prunes = element[0].querySelectorAll('.collapse-text');
        var tooltips = element[0].getElementsByClassName('action-bar-tooltip');

        if (scope.maxButtonWidth >= parentWidth) {
          for (var _i = 0; _i < children.length; _i++) {
            children[_i].style['min-width'] = '20px';

            children[_i].onmousemove = function () {
              // has to use document because the tooltips aren't children of the buttons, they're displayed on the
              // primary document body itself.
              var tooltipsAgain = document.getElementsByClassName('action-bar-tooltip'); //Has to loop for the buttons like Archive/unarchive that have two different tooltips

              for (var t = 0; t < tooltipsAgain.length; t++) {
                tooltipsAgain[t].style.display = 'block';
                tooltipsAgain[t].style['margin-left'] = '-' + tooltipsAgain[t].offsetWidth / 2 + 'px';
                tooltipsAgain[t].style['margin-top'] = '-' + (tooltipsAgain[t].offsetHeight + 10) + 'px';
              }
            };
          } // Time to prune


          for (var x = 0; x < prunes.length; x++) {
            prunes[x].style.display = 'none';
          } // Time to... tooltip?


          for (var _x = 0; _x < tooltips.length; _x++) {
            tooltips[_x].style.visibility = 'visible !important';
          }
        } else {
          for (var _i2 = 0; _i2 < children.length; _i2++) {
            children[_i2].style['min-width'] = '70px';

            children[_i2].onmousemove = function () {
              var tooltipsAgain = document.getElementsByClassName('action-bar-tooltip');

              for (var t = 0; t < tooltipsAgain.length; t++) {
                tooltipsAgain[t].style.display = 'none';
              }
            };
          } // Time to prune


          for (var _x2 = 0; _x2 < prunes.length; _x2++) {
            prunes[_x2].style.display = 'inline';
          } // Time to... tooltip?


          for (var _x3 = 0; _x3 < tooltips.length; _x3++) {
            tooltips[_x3].style.visibility = 'hidden';
          }
        }
      } // Watch for window size changes


      scope.$watch(function () {
        return element[0].clientWidth;
      }, function (oldValue, newValue) {
        if (oldValue !== newValue) {
          reconfigurator();
        }
      }, true); // Watch for new buttons

      scope.$watch(function () {
        var checkChildren = element[0].querySelectorAll('.child');
        var checkWidth = 0;

        for (var i = 0; i < checkChildren.length; i++) {
          var startingComputedStyle = $window.getComputedStyle(checkChildren[i]);
          checkWidth = checkWidth + parseInt(checkChildren[i].offsetWidth, 10) + parseInt(startingComputedStyle.marginLeft, 10) + parseInt(startingComputedStyle.marginRight, 10);
        }

        return checkWidth;
      }, function (oldValue, newValue) {
        if (oldValue !== newValue) {
          reconfigurator();
        }
      }, true);
    }
  };
}]);