/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.directives').directive('ctsStatusBar', function () {
    return {
      restrict: 'E',
      template: '<canvas class="cts-status-bar" height="45" style="width: 100%;"></canvas>',
      scope: {
        bubbles: '=data'
      },
      link: function link(scope, element, attr, ngModelCtrl) {
        /* Data should look something like this:
            data = {
                color: 'rgb(120, 120, 120)',
                total: 5,
                current: 3,
                status: 'Processing',
                progress: 10,
                label: 'some text label description'
            };
         */
        // Create our canvases. One for continuous updates (off-screen) and one for displaying the finished product (on-screen)
        // We do off-screen rendering because it is easier on the browser and saves us some pain if there's a LOT of these on the page
        scope.canvas = element.find('canvas')[0];
        var renderCanvas = document.createElement('canvas');
        renderCanvas.width = scope.canvas.width;
        renderCanvas.height = scope.canvas.height; // Retrieve the drawing contexts for each canvas

        var context = renderCanvas.getContext('2d');
        var realContext = scope.canvas.getContext('2d'); // If the user's browser doesn't support canvas then display the status in textual form. (They should also consider getting a new computer)

        if (!scope.canvas.getContext) {
          // canvas-unsupported code here
          var contents = scope.bubbles.progress !== undefined ? scope.bubbles.status + ' (' + scope.bubbles.progress + '%)' : scope.bubbles.status;
          element[0].innerHTML = "<div style='color: " + scope.bubbles.color + "; height: 45px; line-height:45px; width: 100%; background-color: rgb(255, 255, 255);'>" + contents + '</div>';
          return false;
        } // Object to store fixed bubble properties


        var BubbleProps = {
          y: 15,
          radius: 8,
          statusY: 30
        }; // Object to store fixed connector properties

        var ConnectorProps = {
          y: 13,
          height: 4,
          width: scope.canvas.width * 0.35 - scope.canvas.width * 0.05 - 12
        }; // Object to store colors that won't change

        var ColorLevel = {
          invisible: 'rgba(0, 0, 0, 0)',
          inactive: 'rgb(120, 120, 120)'
        }; // Sub-function to render the individual bubbles

        function drawBubble(position
        /* 1-4 */
        , fillColor
        /* rgb(x, y, z) */
        , status
        /* 'text' */
        , progress
        /* 0-100 */
        ) {
          // Calculate the x-coordinate for the bubble's center
          var xPosition = scope.canvas.width * (0.9 / (scope.bubbles.total - 1) * position + 0.05); // draw the bubble and fill it with specified color

          context.font = '14px Roboto';
          context.fillStyle = progress === undefined ? fillColor : ColorLevel.invisible;
          context.beginPath();
          context.arc(xPosition, BubbleProps.y, BubbleProps.radius, 0, Math.PI * 2, true);
          context.fill(); // Draw step number in the bubble

          context.fillStyle = fillColor === ColorLevel.invisible || progress !== undefined ? ColorLevel.inactive : 'rgb(255, 255, 255)';
          context.textBaseline = 'middle';
          var bubbleLabel = progress !== undefined ? '...' : position + 1;
          context.fillText(bubbleLabel, xPosition - context.measureText(bubbleLabel).width / 2, BubbleProps.y); // If status was passed, display it under the bubble

          if (status !== undefined) {
            // Set styles to be used
            context.font = '14px Roboto';
            context.fillStyle = ColorLevel.inactive;
            context.textBaseline = 'hanging'; // Build the status text (in case there is a percentage to add)

            status = progress !== undefined && progress > 0 ? status + ' (' + progress + '%)' : status; // Determine text positioning to prevent overflow off the canvas

            var newX = xPosition - context.measureText(status).width / 2; // Keep it from floating out of bounds to the right

            newX = newX + context.measureText(status).width + 2 < scope.canvas.width ? newX : scope.canvas.width - context.measureText(status).width - 2; // Keep it from floating out of bounds to the left

            newX = newX >= 2 ? newX : 2; // Draw the text to the canvas

            context.fillText(status, newX, BubbleProps.statusY);
          }
        } // Sub-function to render the connectors between bubbles


        function drawConnector(position
        /* 1-3 */
        , fillColor
        /* rgb(x, y, z) */
        ) {
          // Determine positioning and size
          var xPosition = scope.canvas.width * (0.9 / (scope.bubbles.total - 1) * position + 0.05) + 6;
          var connectorWidth = scope.canvas.width * (0.9 / (scope.bubbles.total - 1) + 0.05) - scope.canvas.width * 0.05 - 12; // Set the context's styles

          context.fillStyle = fillColor;
          context.strokeStyle = ColorLevel.inactive;
          context.lineWidth = 0.5; // Draw the connectors

          context.fillRect(xPosition, ConnectorProps.y, connectorWidth, ConnectorProps.height);
          context.strokeRect(xPosition, ConnectorProps.y, connectorWidth, ConnectorProps.height);
        } // Function to build the display


        function drawDisplay() {
          // clear canvas for redraw
          context.clearRect(0, 0, scope.canvas.width, scope.canvas.height);

          if (scope.bubbles) {
            // Draw all the connectors first, so other things will lay over top of them
            for (var i = 0; i < scope.bubbles.total; i++) {
              if (i < scope.bubbles.current - 1) {
                drawConnector(i, scope.bubbles.color);
              } else if (i < scope.bubbles.total - 1) {
                drawConnector(i, ColorLevel.invisible);
              }
            } // Draw all the bubbles and associated text for current


            for (var _i = 0; _i < scope.bubbles.total; _i++) {
              if (_i < scope.bubbles.current - 1) {
                drawBubble(_i, scope.bubbles.color);
              } else if (_i === scope.bubbles.current - 1) {
                drawBubble(_i, scope.bubbles.color, scope.bubbles.status, scope.bubbles.progress);
              } else if (_i === 0 && scope.bubbles.current === 0) {
                drawBubble(0, ColorLevel.invisible, scope.bubbles.status, scope.bubbles.progress);
              } else {
                drawBubble(_i, ColorLevel.invisible);
              }
            }
          }

          realContext.clearRect(0, 0, scope.canvas.width, scope.canvas.height);
          realContext.drawImage(renderCanvas, 0, 0);
        }

        try {
          // Initially render the canvas
          drawDisplay();
        } catch (e) {} // Do nothing, because the data hasn't been populated yet.
        // Catch any changes to properties and re-render the canvas


        scope.$watch(function () {
          try {
            return scope.bubbles ? scope.bubbles.total + '' + scope.bubbles.current + '' + scope.bubbles.status + '' + scope.bubbles.progress + '' + scope.bubbles.color : '';
          } catch (e) {
            return '';
          }
        }, function (newVal, oldVal) {
          if (scope.bubbles !== undefined) {
            drawDisplay();
          }
        });
      }
    };
  });
})();